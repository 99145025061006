import type {
    DashboardFundraisingCampaign, FundraisingCampaign,
    FundraisingCampaignCategory, FundraisingCampaignDonationList,
    FundraisingCampaignQuota,
    FundraisingCampaignReportType,
    FundraisingCampaignType
} from '~/types';
interface FundraisingState {
    selected_fundraising_campaign_category: object | null,
    current_dashboard_fundraising_campaign: DashboardFundraisingCampaign | null,
    current_dashboard_fundraising_campaign_expiry: number|null,
    current_dashboard_fundraising_campaign_images: string[],
    current_dashboard_fundraising_campaign_images_expiry: number|null,
    fundraising_campaign_categories: FundraisingCampaignCategory[]
    fundraising_campaign_categories_expiry: number|null,
    fundraising_campaign_types: FundraisingCampaignType[]
    fundraising_campaign_types_expiry: number|null,
    fundraising_campaign_quota: FundraisingCampaignQuota|null,
    fundraising_campaign_quota_expiry: number|null,
    fundraising_campaign_report_types: FundraisingCampaignReportType[],
    fundraising_campaign_report_types_expiry: number|null,
    current_donation: FundraisingCampaignDonationList|null,
    current_fundraising_campaign: FundraisingCampaign|null,
    caching_time: number;
}
export const useFundraising = defineStore('fundraising', {
    state: (): FundraisingState => {
        return {
            selected_fundraising_campaign_category: null,
            current_dashboard_fundraising_campaign: null,
            current_dashboard_fundraising_campaign_expiry: null,
            current_dashboard_fundraising_campaign_images:[],
            current_dashboard_fundraising_campaign_images_expiry:null,
            fundraising_campaign_categories: [],
            fundraising_campaign_categories_expiry: null,
            fundraising_campaign_types: [],
            fundraising_campaign_types_expiry: null,        
            fundraising_campaign_quota: null,
            fundraising_campaign_quota_expiry: null,
            fundraising_campaign_report_types: [],
            fundraising_campaign_report_types_expiry: null,
            current_donation: null,
            current_fundraising_campaign: null,
            caching_time: 1000*10,
        }
    },
    actions: {
        setFundraisingCampaignCategory(fundraising_campaign_category: object) {
            this.selected_fundraising_campaign_category = fundraising_campaign_category;
        },
        clearFundraisingCampaignCategory() {
            this.selected_fundraising_campaign_category = null;
        },
///////////////////
        setFundraisingCampaignCategories(fundraisingCampaignCategories: FundraisingCampaignCategory[]) {
            this.fundraising_campaign_categories = fundraisingCampaignCategories;
            this.fundraising_campaign_categories_expiry = Date.now();
        },
        clearFundraisingCampaignCategories() {
            this.fundraising_campaign_categories = [];
            this.fundraising_campaign_categories_expiry = null;
        },
        isFundraisingCampaignCategoriesValid(){
            if(this.fundraising_campaign_categories_expiry !=null){
                return ((Date.now() - this.fundraising_campaign_categories_expiry) < this.caching_time);
            }
            return true;
        },

        setFundraisingCampaignTypes(fundraisingCampaignTypes: FundraisingCampaignType[]) {
            this.fundraising_campaign_types = fundraisingCampaignTypes;
            this.fundraising_campaign_types_expiry = Date.now();
        },
        clearFundraisingCampaignTypes() {
            this.fundraising_campaign_types = [];
            this.fundraising_campaign_types_expiry = null;
        },
        isFundraisingCampaignTypesValid(){
            if(this.fundraising_campaign_types_expiry !=null){
                return ((Date.now() - this.fundraising_campaign_types_expiry) < this.caching_time);
            }
            return true;
        },

        setFundraisingCampaignQuota(fundraisingCampaignQuota: FundraisingCampaignQuota) {
            this.fundraising_campaign_quota = fundraisingCampaignQuota;
            this.fundraising_campaign_quota_expiry = Date.now();
        },
        clearFundraisingCampaignQuota() {
            this.fundraising_campaign_quota = {
                minimum_goal_amount_in_cents: 0,
                maximum_goal_amount_in_cents: 0,
                max_attachable_images_quota: 0,
                payment_processing_fee_percentage: 0,
                platform_fee_percentage: 0,
            };
            this.fundraising_campaign_quota_expiry = null;
        },
        isFundraisingCampaignQuotaValid(){
            if(this.fundraising_campaign_quota_expiry !=null){
                return ((Date.now() - this.fundraising_campaign_quota_expiry) < this.caching_time);
            }
            return true;
        },

        setFundraisingCampaignReportTypes(fundraisingCampaignReportTypes: any[]) {
            this.fundraising_campaign_report_types = fundraisingCampaignReportTypes;
            this.fundraising_campaign_report_types_expiry = Date.now();
        },
        clearFundraisingCampaignReportTypes() {
            this.fundraising_campaign_report_types = [];
            this.fundraising_campaign_report_types_expiry = null;
        },
        isFundraisingCampaignReportTypesValid(){
            if(this.fundraising_campaign_report_types_expiry !=null){
                return ((Date.now() - this.fundraising_campaign_report_types_expiry) < this.caching_time);
            }
            return true;
        },

        setCurrentDashboardFundraisingCampaign(dashboardFundraisingCampaign: DashboardFundraisingCampaign) {
            this.current_dashboard_fundraising_campaign = dashboardFundraisingCampaign;
            this.current_dashboard_fundraising_campaign_expiry = Date.now();
        },

        clearCurrentDashboardFundraisingCampaign() {
            this.current_dashboard_fundraising_campaign = null;
            this.current_dashboard_fundraising_campaign_expiry = null;
        },
        
        isCurrentDashboardFundraisingCampaignValid(){
            if(this.current_dashboard_fundraising_campaign_expiry !=null){
                return ((Date.now() - this.current_dashboard_fundraising_campaign_expiry) < this.caching_time);
            }
            return true;
        },

        increaseTimelineUpdateCountForCurrentDashboardFundraisingCampaign(){
           if(this.current_dashboard_fundraising_campaign){
            this.current_dashboard_fundraising_campaign.timeline_updates_count += 1;
           } 
        },

        decreaseTimelineUpdateCountForCurrentDashboardFundraisingCampaign(){
            if(this.current_dashboard_fundraising_campaign){
             this.current_dashboard_fundraising_campaign.timeline_updates_count -= 1;
            } 
         },

        setCurrentDashboardFundraisingCampaignImages(imageUrls: string[]){
            this.current_dashboard_fundraising_campaign_images = imageUrls;
            this.current_dashboard_fundraising_campaign_images_expiry = Date.now();
        },
        clearCurrentDashboardFundraisingCampaignImages(){
            this.current_dashboard_fundraising_campaign_images = [];
            this.current_dashboard_fundraising_campaign_images_expiry = null;
        },
        isCurrentDashboardFundraisingCampaignImagesValid(){
            if(this.current_dashboard_fundraising_campaign_images_expiry !=null){
                return ((Date.now() - this.current_dashboard_fundraising_campaign_images_expiry) < this.caching_time);
            }
            return true;
        },
        addImageToCurrentDashboardFundraisingCampaignImages(imageUrl: string){
            this.current_dashboard_fundraising_campaign_images.push(imageUrl);
            this.current_dashboard_fundraising_campaign?.images.push(imageUrl);
        },
        removeImageFromCurrentDashboardFundraisingCampaignImagesAtIndex(index: number){
            this.current_dashboard_fundraising_campaign_images.splice(index, 1);
            this.current_dashboard_fundraising_campaign?.images.splice(index, 1);
        },
        ///////////////////
        // setFundraisingCampaignFeaturedPackages(classifiedAdFeaturedPackages: ClassifiedAdFeaturedPackage[]) {
        //     this.fundraising_campaign_featured_packages = classifiedAdFeaturedPackages;
        //     this.fundraising_campaign_featured_packages_expiry = Date.now();
        // },
        // clearClassifiedAdFeaturedPackages() {
        //     this.fundraising_campaign_featured_packages = [];
        //     this.fundraising_campaign_featured_packages_expiry = null;
        // },
        // isClassifiedAdFeaturedPackagesValid(){
        //     if(this.fundraising_campaign_featured_packages_expiry !=null){
        //         return ((Date.now() - this.fundraising_campaign_featured_packages_expiry) < this.caching_time);
        //     }
        //     return true;
        // },

        setCurrentDonation(donation:FundraisingCampaignDonationList){
            this.current_donation = donation;
        },

        clearCurrentDonation(){
            this.current_donation = null;
        },

        setCurrentFundraisingCampaign(fundraisingCampaign: FundraisingCampaign){
            this.current_fundraising_campaign = fundraisingCampaign;
        },

        clearCurrentFundraisingCampaign(){
            this.current_fundraising_campaign = null;
        }
    },
})